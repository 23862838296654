import { render, staticRenderFns } from "./Spiner.vue?vue&type=template&id=3e0cdfa5&"
import script from "./Spiner.vue?vue&type=script&lang=js&"
export * from "./Spiner.vue?vue&type=script&lang=js&"
import style0 from "./Spiner.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../shared/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VProgressCircular,VRow})
